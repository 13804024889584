import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, notification, Typography, Upload, UploadFile } from 'antd';
import axios from 'axios';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MathJax from 'react-mathjax-preview';
import { useEffectOnce, usePrevious, useWindowSize } from 'react-use';
import styled from 'styled-components';
import { minimumHeight, minimumWidth } from '../constants';
import { getAPIURL } from '../host';
import { useStudy, useStudyDispatch } from '../StudyContext';

export enum AnalysisTask {
  TASK_1 = 'task1',
  TASK_2 = 'task2',
  TASK_3 = 'task3'
}

const openDataURIInNewTab = (dataURI: string, type: string) => {
  const blob = new Blob([Buffer.from(dataURI.substring(`data:${type};base64,`.length), 'base64')], {
    type
  });
  const blobURL = URL.createObjectURL(blob);

  window.open(blobURL, '_blank');
};
const windowSizeWarningNotificationKey = 'window-size-warning';

const { Paragraph, Title } = Typography;

const AnalysisTasks = ({ task }: { task: AnalysisTask }) => {
  const study = useStudy();

  const { setFindingsQuestionnaire } = useStudyDispatch();
  const { height, width } = useWindowSize();
  const previousHeight = usePrevious(height);
  const previousWidth = usePrevious(width);
  const [startedTask, setStartedTask] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (
      (previousHeight >= minimumHeight && height < minimumHeight) ||
      (previousWidth >= minimumWidth && width < minimumWidth)
    ) {
      notification.warning({
        description: (
          <Trans
            i18nKey="study.analysisTasks.sizeWarningNotification.description"
            values={{
              height,
              recommendedHeight: minimumHeight,
              recommendedWidth: minimumWidth,
              width
            }}
          />
        ),
        duration: null,
        key: windowSizeWarningNotificationKey,
        message: translate('study.analysisTasks.sizeWarningNotification.title')
      });
    } else if (height >= minimumHeight && width >= minimumWidth) {
      notification.destroy();
    }
  }, [height, width]);

  useEffect(() => {
    setStartedTask(study.analysisTasks[task]?.beganAt !== undefined);
  }, [task]);

  return (
    <div className="h-full w-full flex flex-col overflow-y-auto">
      <div className="mx-auto w-1/2 flex flex-col">
        <FindingsQuestionnaire task={task} />
        <Title className="!mt-8">{translate(`study.analysisTasks.${task}.title`)}</Title>
        <TaskDescription setStartedTask={setStartedTask} startedTask={startedTask} task={task} />
        <Button
          className="mt-12 self-end"
          disabled={!startedTask || study.analysisTasks[task]?.endedAt !== undefined}
          htmlType="submit"
          onClick={() => {
            setFindingsQuestionnaire(task, {
              modalIsOpen: true
            });
          }}
          type="primary"
        >
          {translate('study.analysisTasks.complete')}
        </Button>
      </div>
    </div>
  );
};

const TaskDescription = ({
  setStartedTask,
  startedTask,
  task
}: {
  setStartedTask: Dispatch<SetStateAction<boolean>>;
  startedTask: boolean;
  task: AnalysisTask;
}) => {
  const { startTask } = useStudyDispatch();
  const study = useStudy();
  const { t: translate } = useTranslation();

  const Model = <MathJax math={'$m \\in M$'} wrapperTag={'span'} />;
  const GroupOfModels = <MathJax math={'$M$'} wrapperTag={'span'} />;
  const OptimizationFunction = (
    <MathJax math={'$$\\min_{m \\in M}{\\frac{size(m)}{acc(m)}}$$'} wrapperTag={'span'} />
  );

  const MarkdownLikeCode = (
    <code
      style={{
        borderRadius: '5px',
        WebkitBorderRadius: '5px',
        border: '1px solid #BCBEC0',
        padding: '2px 4px',
        font: '12px Monaco,Consolas,"Andale Mono","DejaVu Sans Mono",monospace'
      }}
    />
  );

  return (
    <>
      <Paragraph className="!mt-8">
        <Trans
          components={[<b />, <i />, Model, GroupOfModels]}
          i18nKey={`study.analysisTasks.${task}.description`}
        />
      </Paragraph>
      <ol>
        {[1, 2, 3].map((subTask) => (
          <li key={subTask}>
            <Trans
              components={[<b />, <i />, MarkdownLikeCode, OptimizationFunction]}
              i18nKey={`study.analysisTasks.${task}.subTasks.${subTask}`}
            />
          </li>
        ))}
      </ol>
      <Paragraph className="!mb-0">{translate(`study.analysisTasks.${task}.linkLabel`)}</Paragraph>
      <Button
        className="mt-4 self-center"
        disabled={study.analysisTasks[task]?.endedAt !== undefined}
        onClick={() => {
          if (!startedTask) {
            setStartedTask(true);
            startTask(task);
          }

          window.open(translate(`study.analysisTasks.${task}.link`), '_blank');
        }}
        type="primary"
      >
        <Trans
          i18nKey="study.analysisTasks.startWithTask"
          values={{ taskNumber: Object.values(AnalysisTask).indexOf(task) + 1 }}
        />
      </Button>
    </>
  );
};

const ScrollableModal = styled(Modal)`
  .ant-modal-body {
    height: calc(100% - 55px);
    overflow-y: auto;
    padding: 0;
  }

  .ant-modal-content {
    height: 100%;
  }
`;

const FindingsQuestionnaire = ({ task }: { task: AnalysisTask }) => {
  const { completeTask, nextStep, setFindingsQuestionnaire } = useStudyDispatch();
  const study = useStudy();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [areAllRequiredFieldsFilled, setAreAllRequiredFieldsFilled] = useState(
    study.analysisTasks[task].results.findings?.length > 0
  );
  const { t: translate } = useTranslation();

  const taskNumber = Object.values(AnalysisTask).indexOf(task) + 1;

  useEffectOnce(() => {
    if (study && task && fileList.length === 0) {
      axios
        .get(`${getAPIURL()}/study/${study.id}/analysis-tasks/${task}/images`)
        .then((response) => {
          const uploadedImages = response.data as UploadFile[];

          setFileList(uploadedImages);
          setAreAllRequiredFieldsFilled(study.analysisTasks[task].results.findings?.length > 0);
        });
    }
  });

  return (
    <ScrollableModal
      centered
      footer={null}
      onCancel={() => {
        setFindingsQuestionnaire(task, {
          modalIsOpen: false
        });
      }}
      open={study.analysisTasks[task].results.modalIsOpen}
      style={{ height: '75%', overflow: 'hidden' }}
      title={<>📝 {translate(`study.analysisTasks.findingsQuestionnaire.title`)}</>}
      width="50%"
    >
      <Form
        className="!mx-auto max-w-fit !p-4 !pb-0"
        fields={[
          {
            name: 'findings',
            touched: study.analysisTasks[task].results.findings?.length > 0,
            value: study.analysisTasks[task].results.findings
          },
          {
            name: 'images',
            touched: true,
            value: fileList
          }
        ]}
        layout="vertical"
        onFieldsChange={(changedFields, allFields) => {
          if (allFields.every((field) => field.touched))
            setAreAllRequiredFieldsFilled(
              allFields.find((field) => field.name[0] === 'findings')?.value.length > 0
            );

          setFindingsQuestionnaire(task, {
            findings: allFields.find((field) => field.name[0] === 'findings').value
          });
        }}
        onFinish={(values) => {
          setFindingsQuestionnaire(task, {
            findings: values.findings
          });

          completeTask(task);
          nextStep();
        }}
      >
        <Paragraph className="!mb-6">
          <Trans
            i18nKey="study.analysisTasks.findingsQuestionnaire.description"
            values={{
              taskNumber
            }}
          />
        </Paragraph>
        <Form.Item
          label={
            <Title level={5}>
              <Trans
                i18nKey="study.analysisTasks.findingsQuestionnaire.findings.title"
                values={{ taskNumber }}
              />
            </Title>
          }
          name="findings"
          required
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={
            <Title level={5}>
              <Trans
                i18nKey="study.analysisTasks.findingsQuestionnaire.images.title"
                values={{ taskNumber }}
              />
            </Title>
          }
          name="images"
        >
          <Upload
            accept="image/*"
            action={`${getAPIURL()}/study/${study.id}/analysis-tasks/${task}/image`}
            fileList={fileList}
            listType="picture-card"
            method="PUT"
            multiple
            name="image"
            onChange={(changeEvent) => {
              const { file, fileList: newFileList } = changeEvent;

              /*
               * In some cases, an uploaded image is stuck in the "uploading" state. Hence, we need
               * to manually update the status of the file in the file list.
               */
              fileList.forEach((currentFile) => {
                if (currentFile.uid === file.uid) {
                  currentFile.status = file.status;
                }
              });

              setFileList([...newFileList]);
            }}
            onPreview={(file) => {
              // Using `file.thumbUrl` results in diminished quality as it is only the thumbnail of the image
              const fileReader = new FileReader();

              fileReader.onload = () => {
                openDataURIInNewTab(fileReader.result as string, file.type);
              };

              if (file.originFileObj) {
                fileReader.readAsDataURL(file.originFileObj);
              } else {
                openDataURIInNewTab(file.url, file.type);
              }
            }}
            onRemove={(file) =>
              axios.delete(
                `${getAPIURL()}/study/${study.id}/analysis-tasks/${task}/images/${file.uid}`
              )
            }
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>
                {translate('study.analysisTasks.findingsQuestionnaire.images.button')}
              </div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            className="float-right"
            disabled={!areAllRequiredFieldsFilled}
            htmlType="submit"
            type="primary"
          >
            {translate('study.analysisTasks.findingsQuestionnaire.button')}
          </Button>
        </Form.Item>
      </Form>
    </ScrollableModal>
  );
};

export default AnalysisTasks;
