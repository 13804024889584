import 'antd/dist/antd.css';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import App from './App';
import './index.css';
import StudyContextProvider from './StudyContext';

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    load: 'languageOnly',
    nsSeparator: ';',
    supportedLngs: ['en', 'de']
  });

createRoot(document.getElementById('app')).render(
  <StudyContextProvider>
    <App />
  </StudyContextProvider>
);
