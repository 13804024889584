import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import { debounce } from 'lodash';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  ArchitectureType,
  DataType,
  DemographicQuestionnaireValues as DemographicQuestionnaireType,
  MachineLearningType,
  Occupation,
  useStudy,
  useStudyDispatch
} from '../StudyContext';

const { Paragraph, Text, Title } = Typography;

const DemographicQuestionnaire = () => {
  const study = useStudy();
  const [areRequiredFieldsFilled, setAreRequiredFieldsFilled] = useState(
    Object.entries(study.demographicQuestionnaire || {}).every(
      ([key, value]) => value !== undefined || key === 'mailAddress'
    )
  );
  const { nextStep, setDemographicQuestionnaire } = useStudyDispatch();
  const { t: translate } = useTranslation();

  const onFieldsChange = debounce((changedFields, allFields) => {
    onFieldsChange.cancel();

    if (
      changedFields.length === 1 &&
      changedFields[0].name[0] === 'experienceMachineLearning' &&
      changedFields[0].name[1] === 'type' &&
      changedFields[0].value === MachineLearningType.TRADITIONAL
    ) {
      // The type of machine learning experience has changed, hence we need to reset the values of deep learning experience
      const architecturesFieldIndex = allFields.findIndex(
        (field) =>
          field.name[0] === 'experienceMachineLearning' && field.name[1] === 'architectures'
      );
      const dataTypesFieldIndex = allFields.findIndex(
        (field) => field.name[0] === 'experienceMachineLearning' && field.name[1] === 'dataTypes'
      );

      allFields[architecturesFieldIndex].value = {
        other: undefined,
        types: []
      };
      allFields[dataTypesFieldIndex].value = {
        other: undefined,
        types: []
      };
    }

    const machineLearningTypeFieldIndex = allFields.findIndex(
      (field) => field.name[0] === 'experienceMachineLearning' && field.name[1] === 'type'
    );
    setAreRequiredFieldsFilled(
      allFields
        .filter((field) => 'mailAddress' !== field.name[0])
        .every((field) => {
          if (
            field.name[0] === 'experienceMachineLearning' &&
            (field.name[1] === 'architectures' || field.name[1] === 'dataTypes')
          ) {
            return allFields[machineLearningTypeFieldIndex].value ===
              MachineLearningType.TRADITIONAL
              ? true
              : field.touched && (field.value.types.length > 0 || field.value.other?.trim());
          } else if (field.name[0] === 'occupation') {
            if (
              field.value.type === Occupation.ACADEMIC ||
              field.value.type === Occupation.INDUSTRY
            ) {
              return field.touched && field.value.role?.trim();
            } else if (field.value.type === Occupation.OTHER) {
              return field.touched && field.value.other?.trim();
            } else if (field.value.type === Occupation.STUDENT) {
              return field.touched && field.value.subject?.trim();
            }

            return field.value.type;
          } else if (field.name[0] === 'toolchain') {
            if (field.value.tools.length === 1 && field.value.tools[0] === 'other') {
              return field.touched && field.value.other?.trim();
            }

            return field.touched && field.value.tools.length > 0;
          }

          return field.touched;
        })
    );

    setDemographicQuestionnaire(
      allFields.reduce((result, field) => {
        if (field.name.length === 1) {
          result[field.name[0]] = field.value;
        } else {
          // Assumes that all fields are at most two levels deep
          if (!result[field.name[0]]) result[field.name[0]] = {};

          result[field.name[0]][field.name[1]] = field.value;
        }

        return result;
      }, {} as DemographicQuestionnaireType)
    );
  }, 50);

  return (
    <div className="h-full w-full flex flex-col overflow-y-auto">
      <div className="mx-auto w-1/2">
        <Title className="!mt-8">📝 {translate('study.demographicQuestionnaire.title')}</Title>
        <Paragraph className="!mb-12">
          {translate('study.demographicQuestionnaire.opening')}
        </Paragraph>
        <Form
          fields={Object.entries(study.demographicQuestionnaire || {}).map(([name, value]) => ({
            name: [name],
            touched: value !== undefined,
            value
          }))}
          layout="vertical"
          onFieldsChange={onFieldsChange}
          onFinish={(values) => {
            setDemographicQuestionnaire(values);
            nextStep();
          }}
        >
          <Form.Item
            label={
              <Title level={4}>{translate('study.demographicQuestionnaire.age.question')}</Title>
            }
            name="age"
            required
            wrapperCol={{ offset: 1 }}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="18 - 24">18 - 24</Radio>
                <Radio value="25 - 34">25 - 34</Radio>
                <Radio value="35 - 44">35 - 44</Radio>
                <Radio value="45 - 54">45 - 54</Radio>
                <Radio value="above 54">
                  {translate('study.demographicQuestionnaire.age.options.above54')}
                </Radio>
                <Radio value="prefer not to say">
                  {translate('study.demographicQuestionnaire.preferNotToSay')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={
              <Title level={4}>{translate('study.demographicQuestionnaire.gender.question')}</Title>
            }
            name="gender"
            required
            wrapperCol={{ offset: 1 }}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="female">
                  {translate('study.demographicQuestionnaire.gender.options.female')}
                </Radio>
                <Radio value="male">
                  {translate('study.demographicQuestionnaire.gender.options.male')}
                </Radio>
                <Radio value="diverse">
                  {translate('study.demographicQuestionnaire.gender.options.nonBinary')}
                </Radio>
                <Radio value="prefer not to say">
                  {translate('study.demographicQuestionnaire.preferNotToSay')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={
              <Title level={4}>
                {translate('study.demographicQuestionnaire.education.question')}
              </Title>
            }
            name="highestLevelOfEducation"
            required
            wrapperCol={{ offset: 1 }}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="none">
                  {translate('study.demographicQuestionnaire.education.options.none')}
                </Radio>
                <Radio value="high school">
                  {translate('study.demographicQuestionnaire.education.options.highSchool')}
                </Radio>
                <Radio value="bachelor">
                  {translate('study.demographicQuestionnaire.education.options.bachelor')}
                </Radio>
                <Radio value="master">
                  {translate('study.demographicQuestionnaire.education.options.master')}
                </Radio>
                <Radio value="doctorate">
                  {translate('study.demographicQuestionnaire.education.options.doctorate')}
                </Radio>
                <Radio value="prefer not to say">
                  {translate('study.demographicQuestionnaire.preferNotToSay')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={
              <Title level={4}>
                {translate('study.demographicQuestionnaire.occupation.question')}
              </Title>
            }
            name="occupation"
            required
            wrapperCol={{ offset: 1 }}
          >
            <OccupationField />
          </Form.Item>
          <Form.Item
            label={
              <Title level={4}>
                {translate('study.demographicQuestionnaire.experienceProgramming.question')}
              </Title>
            }
            name={['experienceProgramming', 'years']}
            required
            wrapperCol={{ offset: 1 }}
          >
            <InputNumber min={0} max={100} />
          </Form.Item>
          <ContextField name={['experienceProgramming', 'context']} />
          <Form.Item
            label={
              <Title level={4}>
                {translate(
                  'study.demographicQuestionnaire.experienceMachineLearning.years.question'
                )}
              </Title>
            }
            name={['experienceMachineLearning', 'years']}
            required
            wrapperCol={{ offset: 1 }}
          >
            <InputNumber min={0} max={100} />
          </Form.Item>
          <ContextField name={['experienceMachineLearning', 'context']} />
          <Form.Item
            label={
              <Title level={4}>
                {translate(
                  'study.demographicQuestionnaire.experienceMachineLearning.type.question'
                )}
              </Title>
            }
            labelCol={{ offset: 1 }}
            name={['experienceMachineLearning', 'type']}
            required
            wrapperCol={{ offset: 2 }}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="traditional">
                  {translate(
                    'study.demographicQuestionnaire.experienceMachineLearning.type.options.traditional'
                  )}
                </Radio>
                <Radio value="deepLearning">
                  {translate(
                    'study.demographicQuestionnaire.experienceMachineLearning.type.options.deepLearning'
                  )}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            hidden={
              study.demographicQuestionnaire?.experienceMachineLearning?.type !==
              MachineLearningType.DEEP_LEARNING
            }
            label={
              <Title level={4}>
                {translate(
                  'study.demographicQuestionnaire.experienceMachineLearning.dataTypes.question'
                )}
              </Title>
            }
            labelCol={{ offset: 1 }}
            name={['experienceMachineLearning', 'dataTypes']}
            required
            wrapperCol={{ offset: 2 }}
          >
            <DataTypesField />
          </Form.Item>
          <Form.Item
            hidden={
              study.demographicQuestionnaire?.experienceMachineLearning?.type !==
              MachineLearningType.DEEP_LEARNING
            }
            label={
              <Title level={4}>
                {translate(
                  'study.demographicQuestionnaire.experienceMachineLearning.architectureTypes.question'
                )}
              </Title>
            }
            labelCol={{ offset: 1 }}
            name={['experienceMachineLearning', 'architectures']}
            required
            wrapperCol={{ offset: 2 }}
          >
            <ArchitectureTypesField />
          </Form.Item>
          <Form.Item
            label={
              <Title level={4}>
                {translate('study.demographicQuestionnaire.toolchain.question')}
              </Title>
            }
            name="toolchain"
            required
            wrapperCol={{ offset: 1 }}
          >
            <ToolchainField />
          </Form.Item>
          <Form.Item
            help={
              <div className="mb-6 mt-3" style={{ maxWidth: '75%' }}>
                <Trans
                  components={[<i />]}
                  i18nKey="study.demographicQuestionnaire.mailAddress.help"
                ></Trans>
              </div>
            }
            label={
              <Title level={4}>
                {translate('study.demographicQuestionnaire.mailAddress.question')}
              </Title>
            }
            name="mailAddress"
            wrapperCol={{ offset: 1 }}
          >
            <Input className="!w-2/3" />
          </Form.Item>
          <Form.Item>
            <Button
              className="float-right"
              disabled={!areRequiredFieldsFilled}
              htmlType="submit"
              type="primary"
            >
              {translate('study.demographicQuestionnaire.button')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

interface ArchitectureTypesFieldValue {
  other: string;
  types: ArchitectureType[];
}

const ArchitectureTypesField = ({
  onChange,
  value
}: {
  onChange?: (value: ArchitectureTypesFieldValue) => void;
  value?: ArchitectureTypesFieldValue;
}) => {
  const study = useStudy();
  const { t: translate } = useTranslation();

  return (
    <Checkbox.Group
      onChange={(checkedValues) =>
        onChange?.({
          other: checkedValues.includes(ArchitectureType.OTHER)
            ? study.demographicQuestionnaire.experienceMachineLearning.architectures.other
            : undefined,
          types: checkedValues as ArchitectureType[]
        })
      }
      style={{ width: '100%' }}
      value={value?.types}
    >
      <Row>
        <Col span={24}>
          <Checkbox value="autoEncoder">
            {translate(
              'study.demographicQuestionnaire.experienceMachineLearning.architectureTypes.options.autoEncoder'
            )}
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox value="convolutionalNeuralNetwork">
            {translate(
              'study.demographicQuestionnaire.experienceMachineLearning.architectureTypes.options.convolutionalNeuralNetwork'
            )}
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox value="denseNeuralNetwork">
            {translate(
              'study.demographicQuestionnaire.experienceMachineLearning.architectureTypes.options.denseNeuralNetwork'
            )}
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox value="generativeAdversarialNetwork">
            {translate(
              'study.demographicQuestionnaire.experienceMachineLearning.architectureTypes.options.generativeAdversarialNetwork'
            )}
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox value="recurrentNeuralNetwork">
            {translate(
              'study.demographicQuestionnaire.experienceMachineLearning.architectureTypes.options.recurrentNeuralNetwork'
            )}
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox value="transformer">
            {translate(
              'study.demographicQuestionnaire.experienceMachineLearning.architectureTypes.options.transformer'
            )}
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox value="other">
            <div className="flex">
              <span>
                {translate(
                  'study.demographicQuestionnaire.experienceMachineLearning.architectureTypes.options.other'
                )}
                &nbsp;
              </span>
              <Form.Item noStyle required>
                <Input
                  onChange={(event) =>
                    onChange?.({
                      ...study.demographicQuestionnaire.experienceMachineLearning.architectures,
                      other: event.target.value
                    })
                  }
                  size="small"
                  value={value?.other}
                />
              </Form.Item>
            </div>
          </Checkbox>
        </Col>
      </Row>
    </Checkbox.Group>
  );
};

const ContextField = ({ name }: { name: string[] }) => {
  const { t: translate } = useTranslation();

  return (
    <Form.Item
      label={
        <Title level={4}>{translate('study.demographicQuestionnaire.context.question')}</Title>
      }
      labelCol={{ offset: 1 }}
      name={name}
      required
      wrapperCol={{ offset: 2 }}
    >
      <Checkbox.Group style={{ width: '100%' }}>
        <Row>
          <Col span={24}>
            <Checkbox value="education">
              {translate('study.demographicQuestionnaire.context.options.education')}
            </Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="hobby">
              {translate('study.demographicQuestionnaire.context.options.hobby')}
            </Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="job">
              {translate('study.demographicQuestionnaire.context.options.job')}
            </Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
    </Form.Item>
  );
};

interface DataTypesFieldValue {
  other: string;
  types: DataType[];
}

const DataTypesField = ({
  onChange,
  value
}: {
  onChange?: (value: DataTypesFieldValue) => void;
  value?: DataTypesFieldValue;
}) => {
  const study = useStudy();
  const { t: translate } = useTranslation();

  return (
    <Checkbox.Group
      onChange={(checkedValues) =>
        onChange?.({
          other: checkedValues.includes(DataType.OTHER)
            ? study.demographicQuestionnaire.experienceMachineLearning.dataTypes.other
            : undefined,
          types: checkedValues as DataType[]
        })
      }
      style={{ width: '100%' }}
      value={value?.types}
    >
      <Row>
        <Col span={24}>
          <Checkbox value="audio">
            {translate(
              'study.demographicQuestionnaire.experienceMachineLearning.dataTypes.options.audio'
            )}
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox value="image">
            {translate(
              'study.demographicQuestionnaire.experienceMachineLearning.dataTypes.options.image'
            )}
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox value="tabular">
            {translate(
              'study.demographicQuestionnaire.experienceMachineLearning.dataTypes.options.tabular'
            )}
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox value="text">
            {translate(
              'study.demographicQuestionnaire.experienceMachineLearning.dataTypes.options.text'
            )}
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox value="timeSeries">
            {translate(
              'study.demographicQuestionnaire.experienceMachineLearning.dataTypes.options.timeSeries'
            )}
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox value="video">
            {translate(
              'study.demographicQuestionnaire.experienceMachineLearning.dataTypes.options.video'
            )}
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox value="other">
            <div className="flex">
              <span>
                {translate(
                  'study.demographicQuestionnaire.experienceMachineLearning.dataTypes.options.other'
                )}
                &nbsp;
              </span>
              <Form.Item noStyle required>
                <Input
                  onChange={(event) =>
                    onChange?.({
                      ...study.demographicQuestionnaire.experienceMachineLearning.dataTypes,
                      other: event.target.value
                    })
                  }
                  size="small"
                  value={value?.other}
                />
              </Form.Item>
            </div>
          </Checkbox>
        </Col>
      </Row>
    </Checkbox.Group>
  );
};

interface OccupationFieldValue {
  other?: string;
  role?: string;
  subject?: string;
  type: string;
}

const OccupationField = ({
  onChange,
  value
}: {
  onChange?: (value: OccupationFieldValue) => void;
  value?: OccupationFieldValue;
}) => {
  const study = useStudy();
  const { t: translate } = useTranslation();

  const onOccupationFieldChange = (value: Partial<OccupationFieldValue>) => {
    onChange?.({ ...study.demographicQuestionnaire.occupation, ...value });
  };

  return (
    <>
      <Select
        className="!w-1/2 mb-6"
        onChange={(value) =>
          onOccupationFieldChange({
            other: undefined,
            role: undefined,
            subject: undefined,
            type: value
          })
        }
        placeholder={translate('study.demographicQuestionnaire.occupation.placeholder')}
        value={value?.type}
      >
        <Select.Option value={Occupation.ACADEMIC}>
          {translate('study.demographicQuestionnaire.occupation.options.academia')}
        </Select.Option>
        <Select.Option value={Occupation.INDUSTRY}>
          {translate('study.demographicQuestionnaire.occupation.options.industry')}
        </Select.Option>
        <Select.Option value={Occupation.STUDENT}>
          {translate('study.demographicQuestionnaire.occupation.options.student')}
        </Select.Option>
        <Select.Option value={Occupation.OTHER}>
          {translate('study.demographicQuestionnaire.occupation.options.other')}
        </Select.Option>
        <Select.Option value="prefer not to say">
          {translate('study.demographicQuestionnaire.preferNotToSay')}
        </Select.Option>
      </Select>
      {study.demographicQuestionnaire.occupation.type === Occupation.ACADEMIC ? (
        <Form.Item
          label={
            <Title level={4}>
              {translate('study.demographicQuestionnaire.occupation.roleQuestion')}
            </Title>
          }
          required
          wrapperCol={{ offset: 1 }}
        >
          <Input
            className="w-1/2"
            onChange={(event) =>
              onOccupationFieldChange({
                role: event.target.value
              })
            }
            value={value?.role}
          />
        </Form.Item>
      ) : null}
      {study.demographicQuestionnaire.occupation.type === Occupation.INDUSTRY ? (
        <Form.Item
          label={
            <Title level={4}>
              {translate('study.demographicQuestionnaire.occupation.roleQuestion')}
            </Title>
          }
          required
          wrapperCol={{ offset: 1 }}
        >
          <Input
            className="w-1/2"
            onChange={(event) =>
              onOccupationFieldChange({
                role: event.target.value
              })
            }
            value={value?.role}
          />
        </Form.Item>
      ) : null}
      {study.demographicQuestionnaire.occupation.type === Occupation.STUDENT ? (
        <Form.Item
          label={
            <Title level={4}>
              {translate('study.demographicQuestionnaire.occupation.subjectQuestion')}
            </Title>
          }
          required
          wrapperCol={{ offset: 1 }}
        >
          <Input
            className="w-1/2"
            onChange={(event) =>
              onOccupationFieldChange({
                subject: event.target.value
              })
            }
            value={value?.subject}
          />
        </Form.Item>
      ) : null}
      {study.demographicQuestionnaire.occupation.type === Occupation.OTHER ? (
        <Form.Item
          label={
            <Title level={4}>
              {translate('study.demographicQuestionnaire.occupation.otherRequest')}
            </Title>
          }
          required
          wrapperCol={{ offset: 1 }}
        >
          <Input
            className="w-1/2"
            onChange={(event) =>
              onOccupationFieldChange({
                other: event.target.value
              })
            }
            value={value?.other}
          />
        </Form.Item>
      ) : null}
    </>
  );
};

interface ToolchainFieldValue {
  other?: string;
  tools: string[];
}

const ToolchainField = ({
  onChange,
  value
}: {
  onChange?: (value: ToolchainFieldValue) => void;
  value?: ToolchainFieldValue;
}) => {
  const study = useStudy();
  const { t: translate } = useTranslation();

  return (
    <Checkbox.Group
      onChange={(checkedValues) =>
        onChange?.({
          other: checkedValues.includes('other')
            ? study.demographicQuestionnaire.toolchain.other
            : undefined,
          tools: checkedValues as string[]
        })
      }
      style={{ width: '100%' }}
      value={value?.tools}
    >
      <Row>
        {[
          { name: 'tensorBoard', url: 'https://www.tensorflow.org/tensorboard' },
          { name: 'weightsAndBiases', url: 'https://wandb.ai/site' },
          { name: 'tensorWatch', url: 'https://github.com/microsoft/tensorwatch' },
          { name: 'comet', url: 'https://www.comet.com/site/' },
          { name: 'guildAI', url: 'https://guild.ai/' },
          { name: 'neptune', url: 'https://neptune.ai/' },
          { name: 'polyaxon', url: 'https://polyaxon.com/' },
          { name: 'sacred', url: 'https://github.com/IDSIA/sacred' }
        ].map((tool, index) => (
          <Col key={index} span={24}>
            <Checkbox value={tool.name}>
              <Trans
                components={[<a href={tool.url} target="_blank" />]}
                i18nKey={`study.demographicQuestionnaire.toolchain.options.${tool.name}`}
              />
            </Checkbox>
          </Col>
        ))}
        <Col span={24}>
          <Checkbox value="other">
            <div className="flex">
              <span>
                {translate('study.demographicQuestionnaire.toolchain.options.other')}&nbsp;
              </span>
              <Form.Item noStyle required>
                <Input
                  onChange={(event) =>
                    onChange?.({
                      ...study.demographicQuestionnaire.toolchain,
                      other: event.target.value
                    })
                  }
                  size="small"
                  value={value?.other}
                />
              </Form.Item>
            </div>
          </Checkbox>
        </Col>
      </Row>
    </Checkbox.Group>
  );
};

export default DemographicQuestionnaire;
