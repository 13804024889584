import React, { ReactNode } from 'react';
import { Radio, Typography } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';

const { Text } = Typography;

const StyledRadioButton = styled(Radio.Button)`
  min-width: 70px;
  text-align: center  ;
`;

/*
 * This wrapper is necessary to enable customized components in `Form` through an event listener,
 * i.e., `onChange`, and a way to pass the value to the `Radio.Group`.
 */
export const ValueWrapper = ({
  lowerEndLabel,
  onChange,
  upperEndLabel,
  value,
  axisLabels
}: {
  lowerEndLabel: string;
  onChange?: (value: number) => void;
  upperEndLabel: string;
  value?: number;
  axisLabels?: ReactNode[];
}) => {
  axisLabels = axisLabels ?? _.range(1, 8).map((v) => `${v}`);

  return (
    <div className="inline-grid grid-cols-[200px_max-content_175px] items-center">
      <Text className="mr-3 justify-self-end" italic>
        {lowerEndLabel}
      </Text>
      <Radio.Group
        buttonStyle="solid"
        onChange={(event) => onChange(event.target.value)}
        value={value}
      >
        {axisLabels.map((axisLabel, index) => (
          <StyledRadioButton key={index} value={index + 1}>
            <b>{axisLabel}</b>
          </StyledRadioButton>
        ))}
      </Radio.Group>
      <Text className="ml-3" italic>
        {upperEndLabel}
      </Text>
    </div>
  );
};
