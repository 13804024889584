import { Button, Form, Input, Typography } from 'antd';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ResultQuestionnaireValues, useStudy, useStudyDispatch } from '../StudyContext';
import { LikertScale } from '../LikertScale';

const { Title } = Typography;
const { TextArea } = Input;

export enum ResultQuestionnaireSection {
  SECTION_USABILITY = 'usability',
  SECTION_TASK_LOAD_AND_USEFULNESS = 'taskLoadAndUsefulness',
  SECTION_EFFECTS_ON_THE_DEBUGGING_WORKFLOW = 'effectsOnTheDebuggingWorkflow',
  SECTION_OPEN_QUESTIONS = 'openQuestions'
}

const comparativeFields = [
  'mentalDemandComparison',
  'temporalDemandComparison',
  'performanceComparison',
  'effortComparison'
];

const textFields = [
  'usabilityComments',
  'taskLoadAndUsefulnessComments',
  'effectsOnTheDebuggingWorkflowComments',
  'comparisonToOtherTools',
  'otherComments'
];

const comparativeAxisLabels = ['– – –', '– –', '–', '0', '+', '+ +', '+ + +'];

const ResultQuestionnaire = ({ section }: { section: ResultQuestionnaireSection }) => {
  const study = useStudy();

  const allFieldValues = [
    ...Object.values(study.resultQuestionnaire.usability),
    ...Object.values(study.resultQuestionnaire.taskLoadAndUsefulness),
    ...Object.values(study.resultQuestionnaire.effectsOnTheDebuggingWorkflow),
    ...Object.values(study.resultQuestionnaire.openQuestions)
  ];

  const [areAllRequiredFieldsFilled, setAreAllRequiredFieldsFilled] = useState(
    allFieldValues.every((value) => value !== undefined)
  );

  const { nextStep, setResultQuestionnaire } = useStudyDispatch();
  const { t: translate } = useTranslation();

  return (
    <div className="h-full w-full flex flex-col overflow-y-auto">
      <div className="mx-auto w-1/2">
        <Title className="!mt-12">
          📝 {`${translate(`study.resultQuestionnaire.${section}.title`)}`}
        </Title>
        <Form
          fields={Object.entries(study.resultQuestionnaire[section] || {}).map(([name, value]) => ({
            name: [name],
            touched: value !== undefined,
            value
          }))}
          layout="vertical"
          onFieldsChange={(changedFields, allFields) => {
            if (
              allFields
                .filter((field) => !textFields.includes(field.name[0]))
                .every((field) => field.touched)
            )
              setAreAllRequiredFieldsFilled(true);

            setResultQuestionnaire(
              allFields.reduce((result, field) => {
                result[field.name[0]] = field.value;

                return result;
              }, {} as ResultQuestionnaireValues),
              section
            );
          }}
          onFinish={(values) => {
            setResultQuestionnaire(values, section);
            nextStep();
          }}
        >
          {Object.keys(study.resultQuestionnaire[section]).map((key) => {
            return textFields.includes(key) ? (
              <Form.Item
                key={key}
                label={
                  <Title level={4}>
                    {translate(`study.resultQuestionnaire.${section}.${key}.title`)}
                  </Title>
                }
                name={key}
                wrapperCol={{ offset: 1 }}
              >
                <TextArea className="!w-2/3" rows={5} />
              </Form.Item>
            ) : (
              <LikertScale
                key={key}
                name={key}
                question={
                  <Trans
                    components={[<u />]}
                    i18nKey={`study.resultQuestionnaire.${section}.${key}.title`}
                  />
                }
                lowerEndLabel={translate(
                  `study.resultQuestionnaire.${section}.${key}.negativeLabel`
                )}
                upperEndLabel={translate(
                  `study.resultQuestionnaire.${section}.${key}.positiveLabel`
                )}
                axisLabels={comparativeFields.includes(key) ? comparativeAxisLabels : undefined}
                withOffset
              />
            );
          })}
          <Form.Item>
            <Button
              className="float-right"
              disabled={!areAllRequiredFieldsFilled}
              htmlType="submit"
              type="primary"
            >
              {translate('study.resultQuestionnaire.button')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ResultQuestionnaire;
