import React, { ReactNode, useState } from 'react';
import { Typography, Radio, Form } from 'antd';
import { ValueWrapper } from './ValueWrapper';
import styled from 'styled-components';

const { Paragraph, Text, Title } = Typography;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 70px;
`;

export const LikertScale = ({
  help,
  lowerEndLabel,
  name,
  question,
  required = true,
  upperEndLabel,
  withOffset = false,
  axisLabels
}: {
  help?: ReactNode;
  lowerEndLabel: string;
  name: string;
  question: ReactNode;
  required?: boolean;
  upperEndLabel: string;
  withOffset?: boolean;
  axisLabels?: ReactNode[];
}) => {
  return (
    <StyledFormItem
      help={help}
      label={<Title level={4}>{question}</Title>}
      name={name}
      required={required}
      wrapperCol={{ offset: withOffset ? 1 : 0 }}
    >
      <ValueWrapper
        lowerEndLabel={lowerEndLabel}
        upperEndLabel={upperEndLabel}
        axisLabels={axisLabels}
      />
    </StyledFormItem>
  );
};
