import { Button, Modal, Radio, Space, Steps } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStudy, useStudyDispatch } from './StudyContext';

export enum StudyStep {
  WELCOME,
  DEMOGRAPHIC_QUESTIONNAIRE,
  INTRODUCTION,
  ANALYSIS_TASK_1,
  ANALYSIS_TASK_2,
  ANALYSIS_TASK_3,
  RESULT_QUESTIONNAIRE_USABILITY,
  RESULT_QUESTIONNAIRE_TASK_LOAD_AND_USEFULNESS,
  RESULT_QUESTIONNAIRE_EFFECTS_ON_THE_DEBUGGING_WORKFLOW,
  RESULT_QUESTIONNAIRE_OPEN_QUESTIONS,
  GOOD_BYE
}

// Convert the current study step to the index in the steps bar.
const studyStepToStepIndex = (step: StudyStep) => {
  switch (step) {
    case StudyStep.WELCOME:
      return 0;
    case StudyStep.DEMOGRAPHIC_QUESTIONNAIRE:
      return 1;
    case StudyStep.INTRODUCTION:
      return 2;
    case StudyStep.ANALYSIS_TASK_1:
      return 3;
    case StudyStep.ANALYSIS_TASK_2:
      return 4;
    case StudyStep.ANALYSIS_TASK_3:
      return 5;
    case StudyStep.RESULT_QUESTIONNAIRE_USABILITY:
    case StudyStep.RESULT_QUESTIONNAIRE_TASK_LOAD_AND_USEFULNESS:
    case StudyStep.RESULT_QUESTIONNAIRE_EFFECTS_ON_THE_DEBUGGING_WORKFLOW:
    case StudyStep.RESULT_QUESTIONNAIRE_OPEN_QUESTIONS:
      return 6;
    case StudyStep.GOOD_BYE:
      return 7;
  }
}

const StudyControls = () => {
  const [likesToWithdraw, setLikesToWithdraw] = useState(false);
  const study = useStudy();
  const { withdraw } = useStudyDispatch();
  const { t: translate } = useTranslation();
  const [withdrawalReason, setWithdrawalReason] = useState(undefined);

  return (
    <>
      <div className="w-full h-[50px] flex justify-between bg-white p-4 pb-0">
        <Steps
          className="!w-[85%]"
          current={studyStepToStepIndex(study.currentStep)}
          items={[
            { title: translate('study.controls.welcome') },
            { title: translate('study.controls.demographicQuestionnaire') },
            { title: translate('study.controls.introduction') },
            { title: translate('study.controls.analysisTask1') },
            { title: translate('study.controls.analysisTask2') },
            { title: translate('study.controls.analysisTask3') },
            { title: translate('study.controls.resultQuestionnaire') }
          ]}
          size="small"
        />
        <Button
          danger
          disabled={
            study.hasWithdrawn ||
            study.currentStep === StudyStep.WELCOME ||
            study.currentStep === StudyStep.GOOD_BYE
          }
          onClick={() => setLikesToWithdraw(true)}
          size="small"
          type="ghost"
        >
          {translate('study.controls.withdraw')}
        </Button>
      </div>
      <Modal
        cancelText={translate('study.controls.withdrawModal.cancel')}
        centered
        closable={false}
        okText={translate('study.controls.withdrawModal.ok')}
        okType={'danger'}
        onCancel={() => setLikesToWithdraw(false)}
        onOk={() => {
          withdraw(withdrawalReason);
          setLikesToWithdraw(false);
        }}
        open={likesToWithdraw}
        title={translate('study.controls.withdrawModal.title')}
      >
        <p>{translate('study.controls.withdrawModal.explanation')}</p>
        <p>{translate('study.controls.withdrawModal.reason.label')}</p>
        <Radio.Group
          className="!ml-2"
          onChange={(event) => setWithdrawalReason(event.target.value)}
          size="small"
        >
          <Space direction="vertical">
            <Radio value="unclear instructions">
              {translate('study.controls.withdrawModal.reason.options.unclearInstructions')}
            </Radio>
            <Radio value="too difficult">
              {translate('study.controls.withdrawModal.reason.options.tooDifficult')}
            </Radio>
            <Radio value="too long">
              {translate('study.controls.withdrawModal.reason.options.tooLong')}
            </Radio>
            <Radio value="not interested">
              {translate('study.controls.withdrawModal.reason.options.notInterested')}
            </Radio>
            <Radio value="technical issues">
              {translate('study.controls.withdrawModal.reason.options.technicalIssues')}
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    </>
  );
};

export default StudyControls;
