var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef } from 'react';
import ShareButton from '../ShareButton';
function createShareButton(networkName, link, optsMap, defaultProps) {
    function CreatedButton(props, ref) {
        var opts = optsMap(props);
        var passedProps = __assign({}, props);
        // remove keys from passed props that are passed as opts
        var optsKeys = Object.keys(opts);
        optsKeys.forEach(function (key) {
            delete passedProps[key];
        });
        return (React.createElement(ShareButton, __assign({}, defaultProps, passedProps, { forwardedRef: ref, networkName: networkName, networkLink: link, opts: optsMap(props) })));
    }
    CreatedButton.displayName = "ShareButton-".concat(networkName);
    return forwardRef(CreatedButton);
}
export default createShareButton;
