import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStudyDispatch } from '../StudyContext';

const { Paragraph, Title } = Typography;

const Introduction = () => {
  const { nextStep } = useStudyDispatch();
  const { i18n, t: translate } = useTranslation();

  return (
    <div className="h-full w-full flex flex-col overflow-y-auto">
      <div className="mx-auto w-1/2">
        <Title className="!mt-8">{translate('study.introduction.title')} 🎬</Title>
        <Paragraph className="!mb-0">{translate('study.introduction.opening')}</Paragraph>
        <iframe
          className="mb-4 mt-4 w-full border-none"
          style={{ aspectRatio: '16/9' }}
          src="https://www.youtube.com/embed/YDOb3-XUZxc?vq=hd1080p60"
          title="iNNspector Online Study — Introduction Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
        <Button className="mt-8 float-right" onClick={nextStep} type="primary">
          {translate('study.introduction.button')}
        </Button>
      </div>
    </div>
  );
};

export default Introduction;
