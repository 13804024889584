import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { EmailIcon, EmailShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';

const GoodBye = () => {
  const { t: translate } = useTranslation();

  return (
    <div className="h-full flex flex-col items-center justify-center">
      <Result
        icon={<></>}
        subTitle={translate('study.goodBye.subTitle')}
        title={<>{translate('study.goodBye.title')} 🙏🏼</>}
      />
      <h3>{translate('study.goodBye.share')}</h3>
      <div className="grid grid-cols-[48px_48px]">
        <EmailShareButton
          body={translate('study.goodBye.emailShareContent')}
          subject={translate('study.goodBye.emailShareSubject')}
          url={`${window.location.origin}?utm_medium=e-mail&utm_source=share-button`}
        >
          <EmailIcon round size={32} />
        </EmailShareButton>
        <WhatsappShareButton
          title={translate('study.goodBye.emailShareContent')}
          url={`${window.location.origin}?utm_medium=whatsapp&utm_source=share-button`}
        >
          <WhatsappIcon round size={32} />
        </WhatsappShareButton>
        {/*<TwitterShareButton*/}
        {/*  title={translate('study.goodBye.twitterShare')}*/}
        {/*  url={`${window.location.origin}?utm_medium=twitter&utm_source=share-button`}*/}
        {/*>*/}
        {/*  <TwitterIcon round size={32} />*/}
        {/*</TwitterShareButton>*/}
        {/*<LinkedinShareButton*/}
        {/*  summary={translate('study.goodBye.linkedInShare')}*/}
        {/*  url={`${window.location.origin}?utm_medium=linkedin&utm_source=share-button`}*/}
        {/*>*/}
        {/*  <LinkedinIcon round size={32} />*/}
        {/*</LinkedinShareButton>*/}
        {/*<RedditShareButton*/}
        {/*  title={translate('name')}*/}
        {/*  url={`${window.location.origin}?utm_medium=reddit&utm_source=share-button`}*/}
        {/*>*/}
        {/*  <RedditIcon round size={32} />*/}
        {/*</RedditShareButton>*/}
      </div>
    </div>
  );
};

export default GoodBye;
