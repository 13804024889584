import { Layout, Result, Space, Typography } from 'antd';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { useKey } from 'react-use';
import styled from 'styled-components';
import AnalysisTasks, { AnalysisTask } from './study-control-steps/AnalysisTasks';
import DemographicQuestionnaire from './study-control-steps/DemographicQuestionnaire';
import GoodBye from './study-control-steps/GoodBye';
import Introduction from './study-control-steps/Introduction';
import Welcome from './study-control-steps/Welcome';
import { useStudy } from './StudyContext';
import StudyControls, { StudyStep } from './StudyControls';
import ResultQuestionnaire, { ResultQuestionnaireSection } from './study-control-steps/ResultQuestionnaire';

const { Text } = Typography;
const { Content } = Layout;

const FullHeightLayout = styled(Layout)`
  height: 100%;
`;

const App = () => {
  const [hasEscapedMobileDeviceDisclaimer, setHasEscapedMobileDeviceDisclaimer] = useState(false);
  const study = useStudy();
  const { t: translate } = useTranslation();

  useKey(
    (event) => event.key === 'Escape',
    () => setHasEscapedMobileDeviceDisclaimer(true)
  );

  if (study === undefined) return null;

  if (isMobile && !hasEscapedMobileDeviceDisclaimer) {
    return (
      <Result
        extra={
          <Space className='!text-left' direction='vertical' size='middle'>
            <Text className='text-lg' strong>
              {translate('study.mobileDeviceDisclaimer')}
            </Text>
            <Text className='text-lg' italic>
              <Trans components={[<Text keyboard />]} i18nKey='study.mobileDeviceEscape' />
            </Text>
          </Space>
        }
        status='warning'
        title={<b>{translate('study.unsupportedDevice')}</b>}
      />
    );
  }

  return (
    <FullHeightLayout>
      <StudyControls />
      <Content className='p-5 bg-white'>
        {study.currentStep === StudyStep.WELCOME && !study.hasWithdrawn && <Welcome />}
        {study.currentStep === StudyStep.DEMOGRAPHIC_QUESTIONNAIRE && !study.hasWithdrawn && (
          <DemographicQuestionnaire />
        )}
        {study.currentStep === StudyStep.INTRODUCTION && !study.hasWithdrawn && <Introduction />}
        {study.currentStep === StudyStep.ANALYSIS_TASK_1 && !study.hasWithdrawn && (
          <AnalysisTasks task={AnalysisTask.TASK_1} />
        )}
        {study.currentStep === StudyStep.ANALYSIS_TASK_2 && !study.hasWithdrawn && (
          <AnalysisTasks task={AnalysisTask.TASK_2} />
        )}
        {study.currentStep === StudyStep.ANALYSIS_TASK_3 && !study.hasWithdrawn && (
          <AnalysisTasks task={AnalysisTask.TASK_3} />
        )}
        {study.currentStep === StudyStep.RESULT_QUESTIONNAIRE_USABILITY && !study.hasWithdrawn && (
          <ResultQuestionnaire section={ResultQuestionnaireSection.SECTION_USABILITY} />
        )}
        {study.currentStep === StudyStep.RESULT_QUESTIONNAIRE_TASK_LOAD_AND_USEFULNESS && !study.hasWithdrawn && (
          <ResultQuestionnaire section={ResultQuestionnaireSection.SECTION_TASK_LOAD_AND_USEFULNESS} />
        )}
        {study.currentStep === StudyStep.RESULT_QUESTIONNAIRE_EFFECTS_ON_THE_DEBUGGING_WORKFLOW && !study.hasWithdrawn && (
          <ResultQuestionnaire section={ResultQuestionnaireSection.SECTION_EFFECTS_ON_THE_DEBUGGING_WORKFLOW} />
        )}
        {study.currentStep === StudyStep.RESULT_QUESTIONNAIRE_OPEN_QUESTIONS && !study.hasWithdrawn && (
          <ResultQuestionnaire section={ResultQuestionnaireSection.SECTION_OPEN_QUESTIONS} />
        )}
        {(study.hasWithdrawn || study.currentStep === StudyStep.GOOD_BYE) && <GoodBye />}
      </Content>
    </FullHeightLayout>
  );
};

export default App;
