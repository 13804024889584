import axios from 'axios';
import { isEqual } from 'lodash';
import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import { useLocalStorage } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { getAPIURL } from './host';
import { StudyStep } from './StudyControls';
import { AnalysisTask as AnalysisTaskEnumeration } from './study-control-steps/AnalysisTasks';
import { ResultQuestionnaireSection } from './study-control-steps/ResultQuestionnaire';

enum StudyAction {
  ANSWER_DEMOGRAPHIC_QUESTIONNAIRE,
  ANSWER_RESULT_QUESTIONNAIRE,
  COMPLETE_TASK,
  NEXT_STEP,
  SET_FINDINGS_QUESTIONNAIRE,
  SET_UTM_TAGS,
  START_TASK,
  WITHDRAW
}

interface AnalysisTaskResults {
  findings: string;
  modalIsOpen: boolean;
}

interface AnalysisTask {
  beganAt: Date;
  endedAt: Date;
  results: AnalysisTaskResults;
}

interface AnalysisTasksValues {
  task1: AnalysisTask;
  task2: AnalysisTask;
  task3: AnalysisTask;
}

export enum ArchitectureType {
  AUTO_ENCODER = 'autoEncoder',
  CONVOLUTIONAL_NEURAL_NETWORK = 'convolutionalNeuralNetwork',
  DENSE_NEURAL_NETWORK = 'denseNeuralNetwork',
  GENERATIVE_ADVERSARIAL_NETWORK = 'generativeAdversarialNetwork',
  OTHER = 'other',
  RECURRENT_NEURAL_NETWORK = 'recurrentNeuralNetwork',
  TRANSFORMER = 'transformer'
}

export enum DataType {
  AUDIO = 'audio',
  IMAGE = 'image',
  OTHER = 'other',
  TABULAR = 'tabular',
  TEXT = 'text',
  TIME_SERIES = 'timeSeries',
  VIDEO = 'video'
}

export enum MachineLearningType {
  DEEP_LEARNING = 'deepLearning',
  TRADITIONAL = 'traditional'
}

export enum Occupation {
  ACADEMIC = 'academia',
  INDUSTRY = 'industry',
  OTHER = 'other',
  STUDENT = 'student'
}

export interface DemographicQuestionnaireValues {
  age: string;
  experienceMachineLearning: {
    architectures: {
      other: string;
      types: ArchitectureType[];
    };
    context: string[];
    dataTypes: {
      other: string;
      types: DataType[];
    };
    type: MachineLearningType;
    years: number;
  };
  experienceProgramming: {
    context: string[];
    years: number;
  };
  gender: string;
  highestLevelOfEducation: string;
  mailAddress: string;
  occupation: {
    other: string;
    role: string;
    subject: string;
    type: string;
  };
  toolchain: {
    other: string;
    tools: string[];
  };
}

export interface ResultQuestionnaireValues {
  usability: {
    easeOfUse: number;
    completeness: number;
    effectiveness: number;
    frustration: number;
    usabilityComments: string;
  };
  taskLoadAndUsefulness: {
    mentalDemand: number;
    mentalDemandComparison: number;
    temporalDemand: number;
    temporalDemandComparison: number;
    performance: number;
    performanceComparison: number;
    effort: number;
    effortComparison: number;
    taskLoadAndUsefulnessComments: string;
  };
  effectsOnTheDebuggingWorkflow: {
    improvingThoroughness: number;
    simplifyingAccessToData: number;
    savingTime: number;
    universalDebuggingTool: number;
    effectsOnTheDebuggingWorkflowComments: string;
  };
  openQuestions: {
    ownToolsLikeliness: number;
    comparisonToOtherTools: string;
    otherComments: string;
  };
}

interface StudyState {
  analysisTasks: AnalysisTasksValues;
  beganAt: Date;
  currentStep: number;
  demographicQuestionnaire: DemographicQuestionnaireValues;
  resultQuestionnaire: ResultQuestionnaireValues;
  endedAt: Date;
  id: string;
  utmCampaign: string;
  utmMedium: string;
  utmSource: string;
  hasWithdrawn: boolean;
  withdrawalReason: string;
}

const defaultStudyState: StudyState = {
  analysisTasks: {
    task1: {
      beganAt: undefined,
      endedAt: undefined,
      results: {
        findings: undefined,
        modalIsOpen: false
      }
    },
    task2: {
      beganAt: undefined,
      endedAt: undefined,
      results: {
        findings: undefined,
        modalIsOpen: false
      }
    },
    task3: {
      beganAt: undefined,
      endedAt: undefined,
      results: {
        findings: undefined,
        modalIsOpen: false
      }
    }
  },
  beganAt: undefined,
  currentStep: StudyStep.WELCOME,
  demographicQuestionnaire: {
    age: undefined,
    experienceMachineLearning: {
      architectures: {
        other: undefined,
        types: []
      },
      context: [],
      dataTypes: {
        other: undefined,
        types: []
      },
      type: undefined,
      years: undefined
    },
    experienceProgramming: {
      context: [],
      years: undefined
    },
    gender: undefined,
    highestLevelOfEducation: undefined,
    mailAddress: undefined,
    occupation: {
      other: undefined,
      role: undefined,
      subject: undefined,
      type: undefined
    },
    toolchain: {
      other: undefined,
      tools: []
    }
  },
  resultQuestionnaire: {
    usability: {
      easeOfUse: undefined,
      completeness: undefined,
      effectiveness: undefined,
      frustration: undefined,
      usabilityComments: undefined
    },
    taskLoadAndUsefulness: {
      mentalDemand: undefined,
      mentalDemandComparison: undefined,
      temporalDemand: undefined,
      temporalDemandComparison: undefined,
      performance: undefined,
      performanceComparison: undefined,
      effort: undefined,
      effortComparison: undefined,
      taskLoadAndUsefulnessComments: undefined
    },
    effectsOnTheDebuggingWorkflow: {
      improvingThoroughness: undefined,
      simplifyingAccessToData: undefined,
      savingTime: undefined,
      universalDebuggingTool: undefined,
      effectsOnTheDebuggingWorkflowComments: undefined
    },
    openQuestions: {
      ownToolsLikeliness: undefined,
      comparisonToOtherTools: undefined,
      otherComments: undefined
    }
  },
  endedAt: undefined,
  id: uuidv4(),
  utmCampaign: undefined,
  utmMedium: undefined,
  utmSource: undefined,
  hasWithdrawn: false,
  withdrawalReason: undefined
};

const replaceNullWithUndefined = (object) => {
  for (const key in object) {
    if (object[key] === null) {
      object[key] = undefined;
    } else if (typeof object[key] === 'object') {
      replaceNullWithUndefined(object[key]);
    }
  }
};
const studyStateReducer = (study: StudyState, action: { payload: any; type: StudyAction }) => {
  switch (action.type) {
    case StudyAction.ANSWER_DEMOGRAPHIC_QUESTIONNAIRE:
      return { ...study, demographicQuestionnaire: action.payload };
    case StudyAction.ANSWER_RESULT_QUESTIONNAIRE:
      const {
        resultQuestionnaire,
        section
      }: { resultQuestionnaire: ResultQuestionnaireValues; section: ResultQuestionnaireSection } =
        action.payload;

      return {
        ...study,
        resultQuestionnaire: {
          ...study.resultQuestionnaire,
          [section]: { ...study.resultQuestionnaire[section], ...resultQuestionnaire }
        }
      };
    case StudyAction.COMPLETE_TASK:
      study.analysisTasks[action.payload].endedAt = new Date();
      study.analysisTasks[action.payload].results.modalIsOpen = true;

      return { ...study };
    case StudyAction.NEXT_STEP:
      if (study.currentStep === StudyStep.WELCOME) {
        study.beganAt = new Date();
      } else if (study.currentStep === StudyStep.ANALYSIS_TASK_3) {
        study.endedAt = new Date();
      }

      return {
        ...study,
        currentStep: study.currentStep + 1
      };
    case StudyAction.SET_FINDINGS_QUESTIONNAIRE:
      const {
        findingsQuestionnaire,
        task
      }: { findingsQuestionnaire: AnalysisTaskResults; task: AnalysisTaskEnumeration } =
        action.payload;

      return {
        ...study,
        analysisTasks: {
          ...study.analysisTasks,
          [task]: {
            ...study.analysisTasks[task],
            results: { ...study.analysisTasks[task].results, ...findingsQuestionnaire }
          }
        }
      };
    case StudyAction.SET_UTM_TAGS:
      return { ...study, ...action.payload };
    case StudyAction.START_TASK:
      study.analysisTasks[action.payload].beganAt = new Date();

      return { ...study };
    case StudyAction.WITHDRAW:
      return {
        ...study,
        currentStep: StudyStep.GOOD_BYE,
        endedAt: new Date(),
        hasWithdrawn: true,
        withdrawalReason: action.payload
      };
    default:
      return study;
  }
};

export const useStudy = () => useContext(StudyContext);

export const useStudyDispatch = () => {
  const dispatch = useContext(StudyDispatchContext);

  return {
    completeTask: (task: AnalysisTaskEnumeration) =>
      dispatch({ payload: task, type: StudyAction.COMPLETE_TASK }),
    nextStep: () => dispatch({ payload: undefined, type: StudyAction.NEXT_STEP }),
    setDemographicQuestionnaire: (demographicQuestionnaire: DemographicQuestionnaireValues) =>
      dispatch({
        payload: demographicQuestionnaire,
        type: StudyAction.ANSWER_DEMOGRAPHIC_QUESTIONNAIRE
      }),
    setResultQuestionnaire: (
      resultQuestionnaire: ResultQuestionnaireValues,
      section: ResultQuestionnaireSection
    ) =>
      dispatch({
        payload: {
          resultQuestionnaire,
          section
        },
        type: StudyAction.ANSWER_RESULT_QUESTIONNAIRE
      }),
    setFindingsQuestionnaire: (
      task: AnalysisTaskEnumeration,
      findingsQuestionnaire: Partial<AnalysisTaskResults>
    ) =>
      dispatch({
        payload: { findingsQuestionnaire, task },
        type: StudyAction.SET_FINDINGS_QUESTIONNAIRE
      }),
    startTask: (task: AnalysisTaskEnumeration) =>
      dispatch({ payload: task, type: StudyAction.START_TASK }),
    withdraw: (reason: string) => dispatch({ payload: reason, type: StudyAction.WITHDRAW })
  };
};
const StudyContext = createContext<StudyState>(defaultStudyState);
const StudyDispatchContext =
  createContext<({ payload, type }: { payload: any; type: StudyAction }) => void>(null);

const StudyContextProvider = ({ children }: { children: ReactNode }) => {
  const [storedStudyState, storeStudyState] = useLocalStorage('studyState', defaultStudyState, {
    deserializer: (studyStateAsString: string) => {
      const result = JSON.parse(studyStateAsString);

      replaceNullWithUndefined(result);

      return result;
    },
    raw: false,
    serializer: (studyState: StudyState) =>
      JSON.stringify(studyState, (key, value) => {
        if (value === undefined) {
          return null;
        } else if (['1', '2', '3', '4', '5'].includes(value)) {
          return parseInt(value, 10);
        }

        return value;
      })
  });
  const [study, dispatch] = useReducer(studyStateReducer, storedStudyState);

  useEffect(() => {
    // Record the UTM tags!
    const existingSearchParameters = new URLSearchParams(window.location.search);
    // If not existing, the UTM tags are `null` which is alright in our use case.
    const utmTags = {
      utmCampaign: existingSearchParameters.get('utm_campaign'),
      utmMedium: existingSearchParameters.get('utm_medium'),
      utmSource: existingSearchParameters.get('utm_source')
    };

    dispatch({ payload: utmTags, type: StudyAction.SET_UTM_TAGS });
  }, []);

  useEffect(() => {
    if (!isEqual(study, storedStudyState)) {
      storeStudyState(study);
      if (study.currentStep >= StudyStep.DEMOGRAPHIC_QUESTIONNAIRE) {
        // Only send the study state to the server if the user has started the study, i.e., accepted the terms.
        // noinspection JSIgnoredPromiseFromCall
        axios.put(`${getAPIURL()}/study/${study.id}`, study);
      }
    }
  }, [study]);

  return (
    <StudyContext.Provider value={study}>
      <StudyDispatchContext.Provider value={dispatch}>{children}</StudyDispatchContext.Provider>
    </StudyContext.Provider>
  );
};

export default StudyContextProvider;
